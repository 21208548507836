import React, { useState, useEffect } from 'react';

const B = () => {
    const [table1, setTable1] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editData, setEditData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const initialTable1 = [
            { id: 1, vehicle_no: 'GJ01AB1234', model: 'Tata Ace', capacity: '1 Ton', regions: 'Ahmedabad, Surat, Rajkot,abc' },
            { id: 2, vehicle_no: 'MH02XY5678', model: 'Ashok Leyland', capacity: '10 Ton', regions: 'Mumbai, Pune, Nagpur,Surat' },
            { id: 3, vehicle_no: 'DL03ZP7890', model: 'Eicher Pro', capacity: '5 Ton', regions: 'Delhi, Jaipur, Chandigarh,Ahmedabad' },
            { id: 4, vehicle_no: 'TN04JK4321', model: 'Mahindra Blazo', capacity: '15 Ton', regions: 'Chennai, Bengaluru, Hyderabad,Mumbai' }
        ];

        setTable1(initialTable1);
    }, []);

    const handleRowClick = (row) => {
        setSelectedRow(row.id);
        setEditData(row);
        setIsModalOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSave = () => {
        setTable1((prev) =>
            prev.map((row) => (row.id === selectedRow ? { ...row, ...editData } : row))
        );
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    return (
        <div>
            <table border="1" className="table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Vehicle No</th>
                        <th>Model</th>
                        <th>Capacity</th>
                        <th>Regions</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {table1.map((row) => (
                        <tr key={row.id}>
                            <td>{row.vehicle_no}</td>
                            <td>{row.model}</td>
                            <td>{row.capacity}</td>
                            <td>{row.regions}</td>
                            <td>
                                <button onClick={() => handleRowClick(row)}>Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        width: '400px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    }}>
                        <h3>Edit Vehicle</h3>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Vehicle No:</label>
                            <input
                                type="text"
                                name="vehicle_no"
                                value={editData.vehicle_no}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Model:</label>
                            <input
                                type="text"
                                name="model"
                                value={editData.model}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Capacity:</label>
                            <input
                                type="text"
                                name="capacity"
                                value={editData.capacity}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label>Regions:</label>
                            <input
                                type="text"
                                name="regions"
                                value={editData.regions}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button onClick={handleSave} style={{
                                padding: '10px 20px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}>Save</button>
                            <button onClick={handleCancel} style={{
                                padding: '10px 20px',
                                backgroundColor: '#f44336',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default B;
