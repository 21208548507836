import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from '../Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../helpers/AuthContext";


const OwnerList = () => {

    const { authState } = useContext(AuthContext);

    const [people, setPeople] = useState([]);
    const [entityIdList, setEntityIdList] = useState([]);

    const [owner_name, setOwnerName] = useState('');
    const [owner_wan, setOwnerWan] = useState('');
    const [group_name, setGroupName] = useState('');
    const [owner_contact_details, setOwnerContactDetails] = useState('');
    const [owner_address, setOwnerAddress] = useState('');
    const [owner_id_doc, setOwnerIdDoc] = useState('');
    const [owner_id_doc_no, setOwnerIdDocNo] = useState('');
    const [owner_id_doc_validity, setOwnerIdDocValidity] = useState('');

    const [disableUpdate, setDisableUpdate] = useState(false);
    const [disableAdd, setDisableAdd] = useState(false);

    const [editIndex, setEditIndex] = useState(null);

    const [showAdd, setShowAdd] = useState(false);

    const [showFindSearch, setShowFindSearch] = useState(false);

    const [columnOrder, setColumnOrder] = useState([['owner_name', 'text'], ['owner_wan', 'text'], ['group_name', 'text'], ['owner_contact_details', 'text'], ['owner_address', 'text'], ['owner_id_doc', 'select'], ['owner_id_doc_no', 'text'], ['owner_id_doc_validity', 'date']]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
                return;
            }
            switch (event.key) {
                case 'F2':
                    event.preventDefault();
                    showOneHideAll();
                    setShowAdd(showAdd ? false : true);
                    setDisableUpdate(showAdd ? false : true);
                    break;
                case 'F3':
                    event.preventDefault();
                    showOneHideAll();
                    setShowFindSearch(showFindSearch ? false : true)
                    break;
                case 'F5':
                    event.preventDefault();
                    fetchPeople()
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        fetchPeople();

        axios.post("https://rt-rl-api.aajkaa.in/ddlist/getcolumndata", { columnName: "id_doc" }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            if (response.data[0]['id_doc'] == null) {
                setEntityIdList([])
                return
            }
            setEntityIdList(response.data[0]['id_doc'].split(','))
        });

        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));

        // Cleanup function to destroy tooltips when the component unmounts
        return () => {
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                const tooltip = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
            });
        };
    }, []);

    const fetchPeople = async () => {
        try {
            setProgress(10)
            const response = await axios.get('https://rt-rl-api.aajkaa.in/ownerlist/', {
                headers: { accessToken: localStorage.getItem("accessToken") }
            });
            setPeople(response.data);
            console.log('data', response.data)
            setLoading(false)
            setProgress(100)
        } catch (error) {
            console.error('Error fetching people', error);
        }
    };

    const handleAddPerson = async (e) => {
        e.preventDefault()
        let person = { owner_name, owner_wan, group_name, owner_contact_details, owner_address, owner_id_doc, owner_id_doc_no, owner_id_doc_validity, createdby: authState.username };

        if (editIndex !== null) {
            try {
                person = { owner_name, owner_wan, group_name, owner_contact_details, owner_address, owner_id_doc, owner_id_doc_no, owner_id_doc_validity, updatedby: authState.username };
                await axios.put(`https://rt-rl-api.aajkaa.in/ownerlist/${people[editIndex].id}`, person, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                });
                setDisableAdd(false)
                resetFormAndReloadPeople();
            } catch (error) {
                console.error('Error updating person', error);
            }
        } else {
            try {
                await axios.post('https://rt-rl-api.aajkaa.in/ownerlist/', person, {
                    headers: { accessToken: localStorage.getItem("accessToken") },
                }).then((res) => {
                    toast.success('Added successfully...', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    resetFormAndReloadPeople();
                    setShowAdd(false)
                    setDisableUpdate(false)
                });
            } catch (error) {
                console.error('Error adding person', error);
            }
        }
    };

    const resetFormAndReloadPeople = async () => {
        setOwnerName("")
        setOwnerWan("")
        setGroupName("")
        setOwnerContactDetails("")
        setOwnerAddress("")
        setOwnerIdDoc("")
        setOwnerIdDocNo("")
        setOwnerIdDocValidity("")

        setShowAdd(false);
        setShowFindSearch(false);
        setEditIndex(null);

        await fetchPeople();
    };

    const handleEditPerson = (index) => {
        const personToEdit = people[index];
        console.log(personToEdit)
        setEditIndex(index);

        setOwnerName(personToEdit.owner_name)
        setOwnerWan(personToEdit.owner_wan)
        setGroupName(personToEdit.group_name)
        setOwnerContactDetails(personToEdit.owner_contact_details)
        setOwnerAddress(personToEdit.owner_address)
        setOwnerIdDoc(personToEdit.owner_id_doc)
        setOwnerIdDocNo(personToEdit.owner_id_doc_no)
        setOwnerIdDocValidity(personToEdit.owner_id_doc_validity)

    };

    const handleCancelBtn = () => {
        setDisableAdd(false)
        setEditIndex(null);

        setOwnerName("")
        setOwnerWan("")
        setGroupName("")
        setOwnerContactDetails("")
        setOwnerAddress("")
        setOwnerIdDoc("")
        setOwnerIdDocNo("")
        setOwnerIdDocValidity("")
    };

    const handleDeletePerson = async (index) => {
        try {
            await axios.delete(`https://rt-rl-api.aajkaa.in/ownerlist/${people[index].id}`, {
                headers: { accessToken: localStorage.getItem("accessToken") },
            });
            fetchPeople();
        } catch (error) {
            console.error('Error deleting person', error);
        }
    };

    const handleDragStart = (e, position) => {
        e.dataTransfer.setData('text/plain', position);
    };

    const handleDrop = (e, targetPosition) => {
        e.preventDefault();
        const sourcePosition = e.dataTransfer.getData('text/plain');
        const newOrder = [...columnOrder];
        const [removedItem] = newOrder.splice(sourcePosition, 1);
        newOrder.splice(targetPosition, 0, removedItem);

        setColumnOrder(newOrder);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const columnType = {
        'owner_name': 'string',
        'owner_wan': 'string',
        'group_name': 'string',
        'owner_contact_details': 'string',
        'owner_address': 'string',
        'vehicle_id_doc': 'select',
        'vehicle_id_doc_no': 'string',
        'vehicle_id_doc_validity': 'date',
    }

    const [searchColumn, setSearchColumn] = useState("owner_name")
    const [searchSign, setSearchSign] = useState("contains")
    const [searchValue, setSearchValue] = useState("")
    const [signFromColType, setSignFromColType] = useState(false)


    const setSearchColType = (newValue) => {
        setSearchColumn(newValue);
        if (columnType[newValue] === "number") {
            setSignFromColType(true);
            setSearchSign("=")
        }

        if (columnType[newValue] === "string") {
            setSignFromColType(false);
            setSearchSign("contains")
        }
    };


    const filterSearch = (e) => {
        e.preventDefault();
        console.log(searchColumn, searchSign, searchValue)
        axios.post('https://rt-rl-api.aajkaa.in/ownerlist/search', {
            col: searchColumn,
            sign: searchSign,
            value: searchValue
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    }

    const sortPeople = (column, order) => {
        console.log(column, order)
        axios.post('https://rt-rl-api.aajkaa.in/ownerlist/sort', {
            col: column,
            order: order,
        }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            setPeople(response.data)
        })
    };

    const [sortColName, setSortColName] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const handleSort = (e, column) => {
        e.preventDefault();

        setSortColName(prevSortColName => {
            if (column !== prevSortColName || sortOrder === "") {
                setSortOrder("ASC");
            } else if (column === prevSortColName && sortOrder === "ASC") {
                setSortOrder("DESC");
            } else if (sortOrder === "DESC") {
                setSortOrder("ASC");
            }
            return column;
        });

    };

    useEffect(() => {
        if (sortColName != "" && sortOrder != "") {
            sortPeople(sortColName, sortOrder)
        }
        console.log(sortColName, sortOrder);
    }, [sortColName, sortOrder]);


    const [progress, setProgress] = useState(0)

    const setters = {
        owner_name: setOwnerName,
        owner_wan: setOwnerWan,
        group_name: setGroupName,
        owner_contact_details: setOwnerContactDetails,
        owner_address: setOwnerAddress,
        owner_id_doc: setOwnerIdDoc,
        owner_id_doc_no: setOwnerIdDocNo,
        owner_id_doc_validity: setOwnerIdDocValidity,
    };

    const settersvariable = {
        owner_name: owner_name,
        owner_wan: owner_wan,
        group_name: group_name,
        owner_contact_details: owner_contact_details,
        owner_address: owner_address,
        owner_id_doc: owner_id_doc,
        owner_id_doc_no: owner_id_doc_no,
        owner_id_doc_validity: owner_id_doc_validity,
    };


    const setterscolumns = {
        owner_name: "Owner Name",
        owner_wan: "Owner WhatsApp Number",
        group_name: "Group Name",
        owner_contact_details: "Owner Contact Details",
        owner_address: "Owner Address",
        owner_id_doc: "Owner ID Doc",
        owner_id_doc_no: "Owner ID Doc No",
        owner_id_doc_validity: "Owner ID Doc Validity"
    };


    const settersselections = {
        owner_id_doc:
            <select style={{ width: "100%", height: "29.62px" }} value={owner_id_doc} onChange={(e) => setOwnerIdDoc(e.target.value)}>
                <option value="" selected disabled>ID Document</option>
                {entityIdList.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
    };


    const showOneHideAll = () => {
        setShowAdd(false)
        setShowFindSearch(false)
        setDisableUpdate(false)
    }


    return (
        <div className=''>
            <ToastContainer />
            <LoadingBar height={5} color='#f11946' progress={progress} />
            <button className='btn btn-primary me-2' disabled={disableAdd} onClick={() => { showOneHideAll(); setShowAdd(showAdd ? false : true); setDisableUpdate(showAdd ? false : true); }}><i className="fa-solid fa-plus me-2"></i>Add New User (F2)</button>
            <button className='btn btn-success me-2' onClick={() => { showOneHideAll(); setShowFindSearch(showFindSearch ? false : true) }}> <i className="fa-solid fa-magnifying-glass me-2"></i>Find / Search (F3)</button>
            <button className='btn btn-warning me-2' onClick={() => fetchPeople()}><i className="fa-solid fa-eraser me-2"></i>Clear all find and sort (F5)</button>

            {
                showAdd && (
                    <div className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <form className='d-flex flex-wrap gap-2' onSubmit={(e) => handleAddPerson(e)}>
                            <input type="text" placeholder='Owner Name' value={owner_name} onChange={(e) => setOwnerName(e.target.value)} />
                            <input type="text" placeholder='Owner Whatsapp Number' value={owner_wan} onChange={(e) => setOwnerWan(e.target.value)} />
                            <input type="text" placeholder='Group Name' value={group_name} onChange={(e) => setGroupName(e.target.value)} />
                            <input type="text" placeholder='Owner Contact Details' value={owner_contact_details} onChange={(e) => setOwnerContactDetails(e.target.value)} />
                            <input type="text" placeholder='Owner Address' value={owner_address} onChange={(e) => setOwnerAddress(e.target.value)} />
                            <select value={owner_id_doc} onChange={(e) => setOwnerIdDoc(e.target.value)}>
                                <option value="" disabled>ID Document</option>
                                {entityIdList.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                            <input type="text" placeholder='Owner ID Doc No' value={owner_id_doc_no} onChange={(e) => setOwnerIdDocNo(e.target.value)} />
                            <input type="date" placeholder='Owner ID Doc Validity' value={owner_id_doc_validity} onChange={(e) => setOwnerIdDocValidity(e.target.value)} />
                            <button className='btn btn-sm btn-primary' type='submit'>{editIndex !== null ? 'Update' : 'Add'}</button>
                        </form>
                    </div>
                )
            }

            {
                showFindSearch && (
                    <form onSubmit={(e) => filterSearch(e)} className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
                        <select onChange={(e) => { setSearchColType(e.target.value); }} className='me-2' style={{ height: "30px", width: "150px" }}>
                            {columnOrder.map((column, index) => (
                                column[1] == 'checkbox' ? <></> :
                                    <option value={column[0]}>{column[0]}</option>
                            ))}
                        </select>
                        <select onChange={(e) => { setSearchSign(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }}>
                            {signFromColType == true ?
                                <>
                                    <option value="="> = (Equal)</option>
                                    <option value="!="> != (Not Equal)</option>
                                    <option value="<="> {'<='} (Less Than or Equal)</option>
                                    <option value=">=">{'>='} (Greater Than or Equal)</option>
                                    <option value="<"> {'<'} (Less Than)</option>
                                    <option value=">"> {'>'} (Greater Than)</option>
                                </>
                                :
                                <>
                                    <option value="contains">Contains</option>
                                    <option value="doesnotcontains">Does not contains</option>
                                    <option value="beginwith">Begin with</option>
                                </>
                            }
                        </select>

                        <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }} />
                        <button type='submit' className='btn btn-sm btn-success'>Search</button>
                    </form>
                )
            }
            <div className='mt-2' style={{ width: '100%', maxHeight: "70vh", overflow: 'auto', padding: "5px 150px 10px 10px", border: "1px solid black" }}>
                <table className='' style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            {columnOrder.map((column, index) => (
                                <th
                                    key={column}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDrop={(e) => handleDrop(e, index)}
                                    onDragOver={handleDragOver}
                                    onClick={(e) => { handleSort(e, column[0]) }}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={setterscolumns[column[0]]}
                                    style={{ padding: "0 10px", width: "150px", textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                >
                                    <i className="fa-solid fa-sort me-2"></i>{setterscolumns[column[0]]}
                                </th>
                            ))}
                            <th style={{ paddingLeft: "38px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={columnOrder.length + 1}>
                                    <Loading />
                                </td>
                            </tr>
                        ) : (
                            people.map((person, index) => (
                                <tr key={person.id}>
                                    {columnOrder.map((column) => (
                                        <td
                                            key={`${person.id}-${column}`}
                                            style={{ textAlign: "center", maxWidth: '150px', border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {editIndex === index ? (
                                                column[1] === 'select' ? (
                                                    settersselections[column[0]]
                                                ) : column[1] === 'text' || column[1] === 'date' ? (
                                                    <input
                                                        type={column[1]}
                                                        value={settersvariable[column[0]]}
                                                        onChange={(e) => setters[column[0]](e.target.value)}
                                                        style={{ width: '100%' }}
                                                    />
                                                ) : (<></>)
                                            ) : (
                                                column[0] != 'wan' ? (
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={String(person[column[0]])}>{String(person[column[0]])}</span>
                                                ) : (
                                                    (person['wanverified'] == true ? <>{String(person[column[0]])} <i title="Verified" className="text-success ms-2 fa-solid fa-square-check"></i></> : <>{String(person[column[0]])}<i title='Unverified' className="text-warning ms-2 fa-solid fa-triangle-exclamation"></i></>)
                                                )
                                            )}
                                        </td>
                                    ))}
                                    <td>
                                        {editIndex === index ? (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-secondary ms-2' onClick={handleCancelBtn}>Cancel</button>
                                                <button className='btn btn-sm btn-success  ms-2' onClick={handleAddPerson}>Save</button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                <button className='btn btn-sm btn-warning ms-2' disabled={disableUpdate} onClick={() => { setDisableAdd(true); handleEditPerson(index) }}>Edit</button>
                                                <button className='btn btn-sm btn-danger ms-2' onClick={() => handleDeletePerson(index)} style={{ marginRight: "50px" }}>Delete</button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default OwnerList