import React, { useState, useEffect, useRef } from 'react';
import '../common.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const DdList = () => {
let history = useHistory();


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
                return;
            }
            switch (event.key) {
                case 'Backspace':
                    event.preventDefault();
                    history.push('/')
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const scrollRef = useRef(null);
    const [inputValues, setInputValues] = useState([]);
    const [optionsString, setOptionsString] = useState("")
    const [selectedOptionOfDdl, setSelectedOptionOfDdl] = useState("")

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [inputValues]);

    const fetchDataOfColumn = async (columnName) => {
        setInputValues([])
        setOptionsString("")
        try {
            const response = await axios.post('https://rt-rl-api.aajkaa.in/ddlist/getcolumndata', { columnName }, {
                headers: { accessToken: localStorage.getItem("accessToken") },
            });
            if (response.data[0][columnName] == null) {
                setInputValues([""]);
            } else {
                const fetchedData = response.data[0][columnName].split(',');
                setInputValues([...fetchedData, ""]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (index, event) => {
        const values = [...inputValues];
        values[index] = event.target.value;
        setInputValues(values);
        console.log(values)

        if (index === inputValues.length - 1 && event.target.value !== '') {
            setInputValues([...values, '']);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let optionsString = inputValues.filter(value => value).join(',')
        console.log(optionsString, selectedOptionOfDdl)
        if (optionsString == "") {
            optionsString = null
        }
        axios.post('https://rt-rl-api.aajkaa.in/ddlist/updatecolumndata', { optionsString, selectedOptionOfDdl }, {
            headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((response) => {
            if (response.data == 'success') {
                toast.success('Added successfully...', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setSelectedOptionOfDdl("")
                setInputValues([])
            }
        })
    };

    return (
        <>
            <ToastContainer />
            <div className='d-flex container flex-column flex-md-row'>
                <div className='me-5'>
                    <select autoFocus value={selectedOptionOfDdl} onChange={(e) => { fetchDataOfColumn(e.target.value); setSelectedOptionOfDdl(e.target.value) }} defaultValue="">
                        <option value="" disabled selected>Select DDL</option>
                        <option value="id_doc">ID Document</option>
                        <option value="billstatus">Bill Status</option>
                        <option value="casestatus">Case status</option>
                        <option value="conversionstatus">Conversion Status</option>
                        <option value="missedreason">Missed Reason</option>
                        <option value="itemtype">Item Type</option>
                        <option value="executives">Executives</option>
                    </select>
                </div>
                {inputValues.length > 0 && (
                    <div ref={scrollRef} className='ms-5 d-flex flex-column p-2' style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                        {inputValues.map((value, index) => (
                            <input
                                className='mt-1'
                                key={index}
                                type="text"
                                value={value}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder='Add Entity'
                            />
                        ))}
                        <div className='d-flex justify-content-center mt-2'>
                            <button className='btn btn-primary btn-sm' style={{ width: "70%" }} onClick={handleSubmit} >Submit</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DdList;
