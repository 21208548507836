import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../common.css';
import LoadingBar from 'react-top-loading-bar'
import Loading from '../Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../helpers/AuthContext";

const DriverList = () => {

  const { authState } = useContext(AuthContext);

  const [people, setPeople] = useState([]);
  const [entityIdList, setEntityIdList] = useState([]);

  const [drivername, setDriverName] = useState('');
  const [address, setAddress] = useState('');
  const [contactdetails, setContactDetails] = useState('');
  const [dlno, setDlNo] = useState('');
  const [validupto, setValidUpTo] = useState();
  const [dldetails, setDlDetails] = useState("");
  const [wan, setWan] = useState('');
  const [wanverified, setWanVerified] = useState(0);
  const [reference, setReference] = useState('');
  const [performance, setPerformance] = useState('');
  const [id_doc, setIdDoc] = useState('');
  const [id_doc_no, setIdDocNo] = useState('');
  const [id_doc_validity, setIdDocValidity] = useState('');

  const [disableUpdate, setDisableUpdate] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);

  const [editIndex, setEditIndex] = useState(null);

  const [showAdd, setShowAdd] = useState(false);

  const [showFindSearch, setShowFindSearch] = useState(false);

  const [columnOrder, setColumnOrder] = useState([['drivername', 'text', false, "100px"], ['address', 'text', false, "150px"], ['contactdetails', 'text', false, "150px"], ['dlno', 'text', false, "100px"], ['validupto', 'date', false, "100px"], ['dldetails', 'text', false, "100px"], ['wan', 'text', false, "150px"], ['reference', 'text', false, "100px"], ['id_doc', 'select', false, "100px"], ['id_doc_no', 'text', false, "100px"], ['id_doc_validity', 'date', false, "100px"], ['performance', 'text', true, "100px"]]);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'F2':
          event.preventDefault();
          showOneHideAll();
          setShowAdd(showAdd ? false : true);
          setDisableUpdate(showAdd ? false : true);
          break;
        case 'F3':
          event.preventDefault();
          showOneHideAll();
          setShowFindSearch(showFindSearch ? false : true)
          break;
        case 'F5':
          event.preventDefault();
          fetchPeople()
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetchPeople();
    axios.post("https://rt-rl-api.aajkaa.in/ddlist/getcolumndata", { columnName: "id_doc" }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      if (response.data[0]['id_doc'] == null) {
        setEntityIdList([])
        return
      }
      setEntityIdList(response.data[0]['id_doc'].split(','))
    });
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl));

    // Cleanup function to destroy tooltips when the component unmounts
    return () => {
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltip = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
        if (tooltip) {
          tooltip.dispose();
        }
      });
    };
  }, []);

  const fetchPeople = async () => {
    try {
      setProgress(10)
      const response = await axios.get('https://rt-rl-api.aajkaa.in/driverlist/', {
        headers: { accessToken: localStorage.getItem("accessToken") }
      });
      setPeople(response.data);
      console.log('data', response.data)
      setLoading(false)
      setProgress(100)
    } catch (error) {
      console.error('Error fetching people', error);
    }
  };

  const handleAddPerson = async (e) => {
    e.preventDefault()
    let person = { drivername, address, contactdetails, dlno, validupto, dldetails, wan, wanverified, reference, performance, id_doc, id_doc_no, id_doc_validity, createdby: authState.username };

    if (editIndex !== null) {
      try {
        person = { drivername, address, contactdetails, dlno, validupto, dldetails, wan, reference, performance, id_doc, id_doc_no, id_doc_validity, updatedby: authState.username };
        await axios.put(`https://rt-rl-api.aajkaa.in/driverlist/${people[editIndex].id}`, person, {
          headers: { accessToken: localStorage.getItem("accessToken") },
        });
        setDisableAdd(false)
        resetFormAndReloadPeople();
      } catch (error) {
        console.error('Error updating person', error);
      }
    } else {
      try {
        await axios.post('https://rt-rl-api.aajkaa.in/driverlist/', person, {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }).then((res) => {
          toast.success('Added successfully...', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          resetFormAndReloadPeople();
          setShowAdd(false)
          setDisableUpdate(false)
        });
      } catch (error) {
        console.error('Error adding person', error);
      }
    }
  };

  const resetFormAndReloadPeople = async () => {
    setDriverName("")
    setAddress("")
    setContactDetails("")
    setDlNo("")
    setValidUpTo()
    setDlDetails("")
    setWan("")
    setReference("")
    setPerformance("")
    setIdDoc("")
    setIdDocNo("")
    setIdDocValidity("")

    setShowAdd(false);
    setShowFindSearch(false);
    setEditIndex(null);

    await fetchPeople();
  };

  const handleEditPerson = (index) => {
    const personToEdit = people[index];

    setEditIndex(index);

    setDriverName(personToEdit.drivername)
    setAddress(personToEdit.address)
    setContactDetails(personToEdit.contactdetails)
    setDlNo(personToEdit.dlno)
    setValidUpTo(personToEdit.validupto)
    setDlDetails(personToEdit.dldetails)
    setWan(personToEdit.wan)
    setReference(personToEdit.reference)
    setPerformance(personToEdit.performance)
    setIdDoc(personToEdit.id_doc)
    setIdDocNo(personToEdit.id_doc_no)
    setIdDocValidity(personToEdit.id_doc_validity)
  };

  const handleCancelBtn = () => {
    setDisableAdd(false)
    setEditIndex(null);

    setDriverName("")
    setAddress("")
    setContactDetails("")
    setDlNo("")
    setValidUpTo("")
    setDlDetails("")
    setWan("")
    setReference("")
    setPerformance("")
    setIdDoc("")
    setIdDocNo("")
    setIdDocValidity("")
  };

  const handleDeletePerson = async (index) => {
    try {
      await axios.delete(`https://rt-rl-api.aajkaa.in/driverlist/${people[index].id}`, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      });
      fetchPeople();
    } catch (error) {
      console.error('Error deleting person', error);
    }
  };

  const handleDragStart = (e, position) => {
    e.dataTransfer.setData('text/plain', position);
  };

  const handleDrop = (e, targetPosition) => {
    e.preventDefault();
    const sourcePosition = e.dataTransfer.getData('text/plain');
    const newOrder = [...columnOrder];
    const [removedItem] = newOrder.splice(sourcePosition, 1);
    newOrder.splice(targetPosition, 0, removedItem);

    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const columnType = {
    'drivername': 'string',
    'address': 'string',
    'contactdetails': 'string',
    'dlno': 'string',
    'validupto': 'number',
    'dldetails': 'string',
    'wan': 'string',
    'reference': 'string',
    'performance': 'string',
    'id_doc': 'select',
    'id_doc_no': 'string',
    'id_doc_validity': 'string',
  }

  const [searchColumn, setSearchColumn] = useState("drivername")
  const [searchSign, setSearchSign] = useState("contains")
  const [searchValue, setSearchValue] = useState("")
  const [signFromColType, setSignFromColType] = useState(false)


  const setSearchColType = (newValue) => {
    setSearchColumn(newValue);
    if (columnType[newValue] === "number") {
      setSignFromColType(true);
      setSearchSign("=")
    }

    if (columnType[newValue] === "string") {
      setSignFromColType(false);
      setSearchSign("contains")
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    console.log(searchColumn, searchSign, searchValue)
    axios.post('https://rt-rl-api.aajkaa.in/driverlist/search', {
      col: searchColumn,
      sign: searchSign,
      value: searchValue
    }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      setPeople(response.data)
    })
  }

  const sortPeople = (column, order) => {
    console.log(column, order)
    axios.post('https://rt-rl-api.aajkaa.in/driverlist/sort', {
      col: column,
      order: order,
    }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      setPeople(response.data)
    })
  };

  const [sortColName, setSortColName] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const handleSort = (e, column) => {
    e.preventDefault();

    setSortColName(prevSortColName => {
      if (column !== prevSortColName || sortOrder === "") {
        setSortOrder("ASC");
      } else if (column === prevSortColName && sortOrder === "ASC") {
        setSortOrder("DESC");
      } else if (sortOrder === "DESC") {
        setSortOrder("ASC");
      }
      return column;
    });

  };

  useEffect(() => {
    if (sortColName != "" && sortOrder != "") {
      sortPeople(sortColName, sortOrder)
    }
    console.log(sortColName, sortOrder);
  }, [sortColName, sortOrder]);


  const [progress, setProgress] = useState(0)

  const setters = {
    drivername: setDriverName,
    address: setAddress,
    contactdetails: setContactDetails,
    dlno: setDlNo,
    validupto: setValidUpTo,
    dldetails: setDlDetails,
    wan: setWan,
    reference: setReference,
    performance: setPerformance,
    id_doc: setIdDoc,
    id_doc_no: setIdDocNo,
    id_doc_validity: setIdDocValidity,
  };

  const settersvariable = {
    drivername: drivername,
    address: address,
    contactdetails: contactdetails,
    dlno: dlno,
    validupto: validupto,
    dldetails: dldetails,
    wan: wan,
    reference: reference,
    performance: performance,
    id_doc: id_doc,
    id_doc_no: id_doc_no,
    id_doc_validity: id_doc_validity,
  };


  const setterscolumns = {
    drivername: "Driver Name",
    address: "Address",
    contactdetails: "Contact Details",
    dlno: "DL No",
    validupto: "Valid Upto",
    dldetails: "DL Details",
    wan: "WAN",
    reference: "Reference",
    performance: "Performance",
    id_doc: "ID Document",
    id_doc_no: "ID Document No",
    id_doc_validity: "ID Document Validity",
  };


  const settersselections = {
    id_doc:
      <select style={{ width: "100%", height: "29.62px" }} className='' value={id_doc} onChange={(e) => setIdDoc(e.target.value)}>
        <option value="" disabled>ID Document</option>
        {entityIdList.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
  };


  const showOneHideAll = () => {
    setShowAdd(false)
    setShowFindSearch(false)
    setDisableUpdate(false)
  }


  return (
    <div className=''>
      <ToastContainer />
      <LoadingBar height={5} color='#f11946' progress={progress} />
      <button className='btn btn-primary me-2' disabled={disableAdd} onClick={() => { showOneHideAll(); setShowAdd(showAdd ? false : true); setDisableUpdate(showAdd ? false : true); }}><i className="fa-solid fa-plus me-2"></i>Add New Driver (F2)</button>
      <button className='btn btn-success me-2' onClick={() => { showOneHideAll(); setShowFindSearch(showFindSearch ? false : true) }}> <i className="fa-solid fa-magnifying-glass me-2"></i>Find / Search (F3)</button>
      <button className='btn btn-warning me-2' onClick={() => fetchPeople()}><i className="fa-solid fa-eraser me-2"></i>Clear all find and sort (F5)</button>

      {
        showAdd && (
          <div className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
            <form className='d-flex flex-wrap gap-2' style={{}} onSubmit={(e) => handleAddPerson(e)}>
              <input type="text" placeholder='Driver Name' value={drivername} className='' onChange={(e) => setDriverName(e.target.value)} autoFocus />
              <input type="text" placeholder='Address' value={address} className='' onChange={(e) => setAddress(e.target.value)} />
              <input type="text" placeholder='Contact Details' value={contactdetails} className='' onChange={(e) => setContactDetails(e.target.value)} />
              <input type="text" placeholder='DL No' value={dlno} className='' onChange={(e) => setDlNo(e.target.value)} />
              <input type="date" placeholder='Valid Up To' value={validupto} className='' onChange={(e) => setValidUpTo(e.target.value)} />
              <input type="text" placeholder='DL Details' value={dldetails} className='' onChange={(e) => setDlDetails(e.target.value)} />
              <input type="text" placeholder='Whatsapp No' value={wan} className='' onChange={(e) => setWan(e.target.value)} />
              <input type="text" placeholder='Reference' value={reference} className='' onChange={(e) => setReference(e.target.value)} />
              <select className='' value={id_doc} onChange={(e) => setIdDoc(e.target.value)}>
                <option value="" disabled>ID Document</option>
                {entityIdList.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
              <input type="text" placeholder='ID Document No' value={id_doc_no} className='' onChange={(e) => setIdDocNo(e.target.value)} />
              <input type="date" placeholder='ID Document Validity' value={id_doc_validity} className='' onChange={(e) => setIdDocValidity(e.target.value)} />
              <button className='btn btn-sm btn-primary' type='submit'>{editIndex !== null ? 'Update' : 'Add'}</button>
            </form>
          </div>
        )
      }

      {
        showFindSearch && (
          <form onSubmit={(e) => filterSearch(e)} className='mt-2' style={{ border: '1px solid black', padding: "10px", width: "fit-content" }}>
            <select onChange={(e) => { setSearchColType(e.target.value); }} className='me-2' style={{ height: "30px", width: "150px" }}>
              {columnOrder.map((column, index) => (
                column[1] == 'checkbox' ? <></> :
                  <option value={column[0]}>{column[0]}</option>
              ))}
            </select>
            <select onChange={(e) => { setSearchSign(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }}>
              {signFromColType == true ?
                <>
                  <option value="="> = (Equal)</option>
                  <option value="!="> != (Not Equal)</option>
                  <option value="<="> {'<='} (Less Than or Equal)</option>
                  <option value=">=">{'>='} (Greater Than or Equal)</option>
                  <option value="<"> {'<'} (Less Than)</option>
                  <option value=">"> {'>'} (Greater Than)</option>
                </>
                :
                <>
                  <option value="contains">Contains</option>
                  <option value="doesnotcontains">Does not contains</option>
                  <option value="beginwith">Begin with</option>
                </>
              }
            </select>
            <input type="text" onChange={(e) => { setSearchValue(e.target.value) }} required className='me-2' style={{ height: "30px", width: "150px" }} />
            <button type='submit' className='btn btn-sm btn-success'>Search</button>
          </form>
        )
      }
      <div className='mt-2' style={{ width: '100%', maxHeight: "70vh", overflow: 'auto', padding: "5px 150px 10px 10px", border: "1px solid black" }}>
        <table className='' style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              {columnOrder.map((column, index) => (
                <th
                  key={column}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDrop={(e) => handleDrop(e, index)}
                  onDragOver={handleDragOver}
                  onClick={(e) => { handleSort(e, column[0]) }}
                  data-bs-toggle="tooltip" data-bs-placement="top" title={setterscolumns[column[0]]}
                  style={{ padding: "0 10px", width: column[3], textAlign: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <i className="fa-solid fa-sort me-2"></i>{setterscolumns[column[0]]}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columnOrder.length + 1}>
                  <Loading />
                </td>
              </tr>
            ) : (
              people.map((person, index) => (
                <tr key={person.id}>
                  {columnOrder.map((column) => (
                    <td
                      key={`${person.id}-${column}`}
                      style={{ textAlign: "center", maxWidth: column[3], border: "1px solid black", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      {editIndex === index ? (
                        column[1] === 'select' ? (
                          settersselections[column[0]]
                        ) : column[1] === 'text' || column[1] === 'date' ? (
                          <input
                            type={column[1]}
                            value={settersvariable[column[0]]}
                            onChange={(e) => setters[column[0]](e.target.value)}
                            style={{ width: '100%' }}
                            disabled={column[2]}
                          />
                        ) : (<></>)
                      ) : (
                        column[0] != 'wan' ? (
                          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={String(person[column[0]])}>{String(person[column[0]])}</span>
                        ) : (
                          (person['wanverified'] == true ? <>{String(person[column[0]])} <i title="Verified" className="text-success ms-2 fa-solid fa-square-check"></i></> : <>{String(person[column[0]])}<i title='Unverified' className="text-warning ms-2 fa-solid fa-triangle-exclamation"></i></>)
                        )
                      )}
                    </td>
                  ))}
                  <td>
                    {editIndex === index ? (
                      <div style={{ display: "flex" }}>
                        <button className='btn btn-sm btn-secondary ms-2' onClick={handleCancelBtn}>Cancel</button>
                        <button className='btn btn-sm btn-success  ms-2' onClick={handleAddPerson}>Save</button>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <button className='btn btn-sm btn-warning ms-2' disabled={disableUpdate} onClick={() => { setDisableAdd(true); handleEditPerson(index) }}>Edit</button>
                        <button className='btn btn-sm btn-danger ms-2' onClick={() => handleDeletePerson(index)} style={{ marginRight: "50px" }}>Delete</button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div >
  )
}

export default DriverList