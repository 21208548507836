import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../helpers/AuthContext";

function Newenquiry() {

  const { authState } = useContext(AuthContext);
  let history = useHistory();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'F2':
          event.preventDefault();
          addDataModal()
          break;
        case 'Backspace':
          event.preventDefault();
          history.push('/');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [history]);

  const [currentDate, setCurrentDate] = useState('');

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const incrementDate = () => {
    if (!currentDate) {
      setCurrentDate(getTodayDate());
      return;
    }
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate.toISOString().split('T')[0]);
  };

  const decrementDate = () => {
    if (!currentDate) {
      setCurrentDate(getTodayDate());
      return;
    }
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate.toISOString().split('T')[0]);
  };

  const handleArrowKeys = (event) => {
    if (event.key === 'ArrowUp') {
      incrementDate();
    } else if (event.key === 'ArrowDown') {
      decrementDate();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleArrowKeys);

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [currentDate]);

  const [allEnquiry, setAllEnquiry] = useState([]);
  const [filteredEnquiry, setFilteredEnquiry] = useState([]);
  const [loader, setLoader] = useState(true);

  const [filter, setFilter] = useState({
    customerName: '',
    vehicleno: '',
    from: '',
    to: ''
  });

  useEffect(() => {
    axios.post("https://rt-rl-api.aajkaa.in/enquiry/getallenquiry").then((res) => {
      setAllEnquiry(res.data);
      setFilteredEnquiry(res.data);
      setLoader(false);
    });
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    const newFilteredData = allEnquiry.filter((enquiry) => {
      const matchesCustomerName = enquiry.customer_name.toLowerCase().includes(filter.customerName.toLowerCase());
      const matchesFromLocation = enquiry.from.toLowerCase().includes(filter.from.toLowerCase());
      const matchesToLocation = enquiry.to.toLowerCase().includes(filter.to.toLowerCase());

      if (!currentDate) {
        return matchesCustomerName && matchesFromLocation && matchesToLocation;
      }

      const enquiryDispatchDate = new Date(enquiry.dispatch_date.split('T')[0]);
      const inputDate = new Date(currentDate);

      return (
        matchesCustomerName &&
        matchesFromLocation &&
        matchesToLocation &&
        enquiryDispatchDate >= inputDate
      );
    });

    setFilteredEnquiry(newFilteredData);
  }, [filter, currentDate, allEnquiry]);

  const [tableInfo, setTableInfo] = useState("enquiry");
  const [allVehicleList, setAllVehicleList] = useState([]);
  const [loader1, setLoader1] = useState(true);

  const [visibilityOfAddEnquiry, setVisibilityOfAddEnquiry] = useState(false);
  const [custNameOrVehiNo, setCustNameOrVehiNo] = useState(true);


  const changeTableInfo = (tableToshow) => {
    if (tableToshow === 'vendor' && allVehicleList.length === 0) {
      axios.get('https://rt-rl-api.aajkaa.in/vehiclelist/', {
        headers: { accessToken: localStorage.getItem("accessToken") },
      }).then((res) => {
        setAllVehicleList(res.data);
        setLoader1(false);
      });
    }
    if (tableToshow == 'enquiry') {
      setVisibilityOfAddEnquiry(false)
      setCustNameOrVehiNo(true)
    } else {
      setVisibilityOfAddEnquiry(true)
      setCustNameOrVehiNo(false)
    }
  };

  const [allUserName, setAllUserName] = useState([]);
  const [allFromLocation, setAllFromLocation] = useState([]);
  const [allToLocation, setAllToLocation] = useState([]);

  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [filterEnabled, setFilterEnabled] = useState(true); // Add this state for the switch

  useEffect(() => {
    axios.post('https://rt-rl-api.aajkaa.in/customerlist/getusersnameonly', {}, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((res) => {
      setAllUserName(res.data);
    });

    axios
      .post('https://rt-rl-api.aajkaa.in/vehiclelist/getalltolocations', {}, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((res) => {
        const allRegions = res.data.map((item) => item.regions.split(',')).flat();
        const uniqueRegions = [...new Set(allRegions.map((region) => region.trim()))];
        setAllToLocation(uniqueRegions);
        setVehicleInfo(res.data);
        setFilteredData(res.data);
        setSelectedRows(Array(res.data.length).fill(true));
      });

    axios
      .post('https://rt-rl-api.aajkaa.in/vehiclelocation/getallfromlocations', {}, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((res) => {
        setAllFromLocation(res.data);
      });
  }, []);

  const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const [rangeValue, setRangeValue] = useState(1);

  useEffect(() => {
    let filtered = vehicleInfo;

    if (filterEnabled && currentDate) {
      const selectedDate = parseDate(currentDate);
      const minDate = new Date(selectedDate);
      const maxDate = new Date(selectedDate);

      minDate.setDate(minDate.getDate() - rangeValue);
      maxDate.setDate(maxDate.getDate() + rangeValue);

      const filteredByDate = allFromLocation.filter((entry) => {
        const entryDate = parseDate(entry.date);
        return entryDate >= minDate && entryDate <= maxDate;
      });

      const vehicleNos = filteredByDate.map((entry) => entry.vehicle_no);
      filtered = filtered.filter((entry) => vehicleNos.includes(entry.vehicle_no));
    }

    if (filterEnabled && filter.from) {
      const filteredByFromLocation = allFromLocation.filter((entry) =>
        entry.location.toLowerCase().includes(filter.from.toLowerCase())
      );
      const vehicleNos = filteredByFromLocation.map((entry) => entry.vehicle_no);
      filtered = filtered.filter((entry) => vehicleNos.includes(entry.vehicle_no));
    }

    if (filterEnabled && filter.to) {
      filtered = filtered.filter((entry) => entry.regions.toLowerCase().includes(filter.to.toLowerCase()));
    }

    if (filterEnabled && filter.vehicleno) {
      filtered = filtered.filter((entry) => entry.vehicle_no.toLowerCase().includes(filter.vehicleno.toLowerCase()));
    }

    setFilteredData(filtered);

    // Update selected rows based on the filter state
    const updatedSelectedRows = vehicleInfo.map((entry) => {
      if (filterEnabled) {
        return filtered.includes(entry); // select if it's part of filtered data
      } else {
        return selectedRows[vehicleInfo.indexOf(entry)] || false; // retain previous selection state
      }
    });

    setSelectedRows(updatedSelectedRows);
  }, [filter.vehicleno,currentDate, filter.from, filter.to, filterEnabled]);


  const [selectAll, setSelectAll] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowCheckboxChange = (index) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = !updatedSelectedRows[index]; // * changed *
    setSelectedRows(updatedSelectedRows);

    // Handle select all logic here based on filterEnabled and the updated row states
    if (filterEnabled) {
      // If filter is enabled, only consider the filtered data for selection logic
      setSelectAll(updatedSelectedRows.every(Boolean));
    } else {
      // Otherwise, base selection logic on all rows
      setSelectAll(selectedRows.every(Boolean));
    }
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedSelectedRows = selectedRows.map((_, i) =>
      filteredData.includes(vehicleInfo[i]) ? newSelectAll : selectedRows[i]
    );
    setSelectedRows(updatedSelectedRows);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationMsg, setShowConfirmationMsg] = useState(false);

  const sendWhatsappToOwner = () => {
    const checkedRows = filteredData.filter((_, index) => selectedRows[vehicleInfo.indexOf(_)]);
    axios.post('https://rt-rl-api.aajkaa.in/onlysendwan/sendwanfornewenquiry', { data: checkedRows, from: filter.from, to: filter.to, date: currentDate }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((res) => {
      alert("sent successfully.")
    });
  };
  const handleConfirm = () => {
    const msg = selectedRows.filter(value => value === true).length
    setShowConfirmationMsg(`Send message${msg == 1 ? "" : "s"} to ${msg} person${msg == 1 ? "" : "s"}`)
    setShowConfirmation(true); // Open the custom confirmation modal
  };

  const handleCancelbtn = () => {
    setShowConfirmation(false); // Close modal if canceled
  };

  const handleConfirmYes = () => {
    setShowConfirmation(false); // Close the modal
    sendWhatsappToOwner(); // Proceed with axios call
  };


  const [selectedRow, setSelectedRow] = useState(null);
  const [editData, setEditData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (row) => {
    setSelectedRow(row.case_id);
    setEditData(row);
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    axios.post('https://rt-rl-api.aajkaa.in/enquiry/updateenquirydata', { updatedata: editData }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((res) => {
      setFilteredEnquiry((prev) =>
        prev.map((row) => (row.case_id === selectedRow ? { ...row, ...editData } : row))
      );
      toast.success('Enquiry updated successfully.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsModalOpen(false);
      setSelectedRow(null);
    });

  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const handleCancel1 = () => {
    setAddDataModalOpen(false);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancel();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCancel, handleCancel1]);

  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [editData1, setEditData1] = useState({});

  const addDataModal = () => {
    setAddDataModalOpen(true);
    setEditData1({ customer_name: filter.customerName, dispatch_date: currentDate, from: filter.from, to: filter.to })
  }


  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setEditData1((prev) => ({
      ...prev,
      [name]: value
    }));
  };



  const handleSave1 = () => {
    console.log(editData1)
    axios.post('https://rt-rl-api.aajkaa.in/enquiry/addenquiry', { newenquirydata: editData1, createdby: authState.username }, {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((res) => {
      console.log(res.data)
    })
    setEditData1({})
    setAddDataModalOpen(false);
  };


  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="container">
        <h2 className='text-primary text-center my-3'>Enquiry</h2>
        <Link to='/' className="btn btn-primary"><i className="fa-solid fa-caret-left mx-2"></i>Back</Link>
        <button className="btn btn-primary ms-2" onClick={() => { setTableInfo(tableInfo === 'enquiry' ? 'vendor' : 'enquiry'); changeTableInfo(tableInfo === 'enquiry' ? 'vendor' : 'enquiry'); }}>{tableInfo === 'enquiry' ? 'Show Vehicles' : 'Show Enquiries'}</button>

        <div className='mt-3 d-flex'>

          {/* Customer name */}

          {custNameOrVehiNo ? (
            <>
              <input className='me-3' autoComplete='off' list="customer_name" name="customerName" placeholder='Customer Name' value={filter.customerName} onChange={handleFilterChange} />
              <datalist id="customer_name">
                {allUserName.map((val, index) => {
                  return (
                    <option key={index} value={val.name} />
                  )
                })}
              </datalist>
            </>
          ) : (
            <>
              <input className='me-3' autoComplete='off' name="vehicleno" placeholder='Vehicle No' value={filter.vehicleno} onChange={handleFilterChange} />
            </>
          )}
          {/* date */}
          <input className='' type="date" value={currentDate} onChange={(e) => { setCurrentDate(e.target.value) }} placeholder="Select date" />
          <span className='d-flex flex-column'>
            <i className="fa-solid fa-angle-up" onClick={incrementDate}></i>
            <i className="fa-solid fa-angle-down" onClick={decrementDate}></i>
          </span>

          {/* from */}
          <input className='me-3 ms-3' autoComplete='off' list="fromlocation" name="from" placeholder='From' value={filter.from} onChange={handleFilterChange} />
          <datalist id="fromlocation">
            {allFromLocation.map((val, index) => {
              return (
                <option key={index} value={val.location} />
              )
            })}
          </datalist>

          {/* to */}
          <input list="tolocation" autoComplete='off' name="to" placeholder='To' value={filter.to} onChange={handleFilterChange} />
          <datalist id="tolocation">
            {allToLocation.map((val, index) => {
              return (
                <option key={index} value={val} />
              )
            })}
          </datalist>

          <input type="number" placeholder="Range (days)" value={rangeValue} onChange={(e) => setRangeValue(Number(e.target.value))} min={1} style={{ width: "50px" }} hidden />

          <button className='btn btn-primary' onClick={addDataModal} hidden={visibilityOfAddEnquiry}>Add Enquiry</button>

        </div>

        <div>
          {tableInfo === 'enquiry' ?
            <>
              <h3 className='mt-2'>Enquiry List</h3>
              <table border="1" className='table-bordered'>
                <thead>
                  <tr>
                    <td className='bg-secondary text-white'>Customer Name</td>
                    <td className='bg-secondary text-white'>Dispatch Date</td>
                    <td className='bg-secondary text-white'>From</td>
                    <td className='bg-secondary text-white'>To</td>
                    <td className='bg-secondary text-white'>Capacity</td>
                    <td className='bg-secondary text-white'>Full Part</td>
                    <td className='bg-secondary text-white'>Case Status</td>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan="7" className="text-center">Loading...</td>
                    </tr>
                  ) : filteredEnquiry.length > 0 ? (
                    filteredEnquiry.map((val, index) => (
                      <tr key={index} onClick={() => handleRowClick(val)}>
                        <td className='fs-5 px-2'>{val.customer_name}</td>
                        <td className='fs-5 px-2'>
                          {new Date(val.dispatch_date).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          }).replace(',', '')}
                        </td>
                        <td className='fs-5 px-2'>{val.from}</td>
                        <td className='fs-5 px-2'>{val.to}</td>
                        <td className='fs-5 px-2'>{val.capacity}</td>
                        <td className='fs-5 px-2'>{val.full_part}</td>
                        <td className='fs-5 px-2'>{val.case_status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No matching enquiries found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
            :
            <>
              <div className='d-flex'>

                <h3>
                  Vehicle List
                </h3>
                <label className="form-switch mt-2">
                  <input
                    type="checkbox"
                    checked={filterEnabled}
                    onChange={() => setFilterEnabled(!filterEnabled)} // Toggle filterEnabled state on switch change
                  />
                  <span>Filter On/Off</span>
                </label>
                <button className='btn btn-primary btn-sm h-50 mt-2 ms-4' onClick={() => { handleConfirm() }}>
                  Send Message(s)
                </button>
              </div>
              <table border="1" className="table-bordered">
                <thead>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    </td>
                    <td className="bg-secondary text-white">Vehicle No</td>
                    <td className="bg-secondary text-white">Model</td>
                    <td className="bg-secondary text-white">Capacity</td>
                    <td className="bg-secondary text-white">Regions</td>
                    <td className="bg-secondary text-white">Owner Name</td>
                    <td className="bg-secondary text-white">Driver Name</td>
                    <td className="bg-secondary text-white">Goods Types</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((val, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows[vehicleInfo.indexOf(val)] || false} // Use the index from the full vehicleInfo list
                          onChange={() => handleRowCheckboxChange(vehicleInfo.indexOf(val))} // Pass the index of the row in the full list
                        />
                      </td>
                      <td className="fs-5 px-2">{val.vehicle_no}</td>
                      <td className="fs-5 px-2">{val.model}</td>
                      <td className="fs-5 px-2">{val.capacity}</td>
                      <td className="fs-5 px-2">{val.regions}</td>
                      <td className="fs-5 px-2">{val.owner_name}</td>
                      <td className="fs-5 px-2">{val.driver_name}</td>
                      <td className="fs-5 px-2">{val.goods_types}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          }
        </div>

        {addDataModalOpen && (
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '65px', zIndex: 1000 }}>
            <div ref={modalRef} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '90%', maxWidth: '1000px', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', }} >
              <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Enquiry</h3>

              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px', marginBottom: '20px' }}>
                <div>
                  <label>Customer Name</label>
                  <input type="text" name="customer_name" value={editData1.customer_name} onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Dispatch Date</label>
                  <input type="date" name="dispatch_date" value={editData1.dispatch_date} onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>From</label>
                  <input name="from" value={editData1.from} onChange={handleInputChange1} autoComplete='off' list="fromlocation" placeholder='From' style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                  <datalist id="fromlocation">
                    {allFromLocation.map((val, index) => {
                      return (
                        <option key={index} value={val.location} />
                      )
                    })}
                  </datalist>
                </div>

                <div>
                  <label>To</label>
                  <input list="tolocation" autoComplete='off' placeholder='To' name="to" value={editData1.to} onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                  <datalist id="tolocation">
                    {allToLocation.map((val, index) => {
                      return (
                        <option key={index} value={val} />
                      )
                    })}
                  </datalist>
                </div>

                <div>
                  <label>Capacity</label>
                  <input type="text" name="capacity" onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Full / Part</label>
                  <input type="text" name="full_part" onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Case Status</label>
                  <input type="text" name="case_status" onChange={handleInputChange1} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '10px 0', borderTop: '1px solid #ddd', backgroundColor: 'white' }}>
                <button onClick={handleCancel1} style={{ padding: '10px 20px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Cancel</button>
                <button onClick={handleSave1} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Save</button>
              </div>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '65px', zIndex: 1000 }}>
            <div ref={modalRef} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '90%', maxWidth: '1000px', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', }} >
              <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Enquiry</h3>

              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px', marginBottom: '20px' }}>
                <div>
                  <label>Customer Name</label>
                  <input type="text" name="customer_name" value={editData.customer_name} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Dispatch Date</label>
                  <input type="date" name="dispatch_date" value={editData.dispatch_date} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>From</label>
                  <input type="text" name="from" value={editData.from} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>To</label>
                  <input type="text" name="to" value={editData.to} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Capacity</label>
                  <input type="text" name="capacity" value={editData.capacity} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Full / Part</label>
                  <input type="text" name="full_part" value={editData.full_part} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>

                <div>
                  <label>Case Status</label>
                  <input type="text" name="case_status" value={editData.case_status} onChange={handleInputChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }} />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '10px 0', borderTop: '1px solid #ddd', backgroundColor: 'white' }}>
                <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Cancel</button>
                <button onClick={handleSave} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} >Save</button>
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div style={{
            position: 'fixed',
            top: 0, left: 0, right: 0, bottom: 0,
            display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}>
            <div style={{
              backgroundColor: 'white', padding: '20px', borderRadius: '8px',
              width: '100%', maxWidth: '500px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              marginTop: "65px"
            }}>
              <p>{showConfirmationMsg}</p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                <button onClick={handleCancelbtn} style={{
                  padding: '10px 20px', backgroundColor: '#f44336', color: 'white',
                  border: 'none', borderRadius: '4px', cursor: 'pointer'
                }} autoFocus>No</button>
                <button onClick={handleConfirmYes} style={{
                  padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white',
                  border: 'none', borderRadius: '4px', cursor: 'pointer'
                }}>Yes</button>
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default Newenquiry;
