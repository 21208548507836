import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "./common.css";
import { AuthContext } from "../helpers/AuthContext";
import Loading from './Loading';

function RTRL() {

  const svgStyle = {
    height: '150px',
    width: 'fit-content',
    margin: '10px'
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const tileStyle = {
    // margin: '10px',
    flexBasis: 'calc(25%)', // 4 tiles per row, adjusting for margins
  };

  const tileTextStyle = {
    fontSize: '1.5vw'
  };

  let history = useHistory();
  const { authState } = useContext(AuthContext);
  const [tileAllowed, setTileAllowed] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://rt-rl-api.aajkaa.in/auth/auth", {
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {
      if (response.data.error) {
        history.push('/login');
        return;
      }
      axios.post("https://rt-rl-api.aajkaa.in/Usertypes/getallowedtilename", authState, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      }).then((response) => {
        setTileAllowed(response.data[0]);
        setLoading(false);
      });
    });
  }, [authState, history]);

  return (
    <div>
      <div className="mx-5">
        <div className="row mx-2 mt-3">
          {loading ? (
            <Loading />
          ) : (
            <div style={containerStyle}>
              {tileAllowed && tileAllowed.newenquiry == 1 && (
                <Link to="/Newenquiry" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#EF597B" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#EF597B" }} />
                      <text x="75" y="85" fill="red" fontSize="40">Enquiry</text>
                    </svg>
                  </div>
                </Link>
              )}
              {/* {tileAllowed && tileAllowed.existingenquiry == 1 && (
                <Link to="/Existingenquiry" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#73B66B" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#73B66B" }} />
                      <text x="75" y="60" fill="red" fontSize="40">Existing</text>
                      <text x="75" y="110" fill="red" fontSize="40">Enquiry</text>
                    </svg>
                  </div>
                </Link>
              )} */}
              {tileAllowed && tileAllowed.vehicalstatus == 1 && (
                <Link to="/Vehicalstatus" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#FF6D31" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#FF6D31" }} />
                      <text x="85" y="60" fill="red" fontSize="40">Vehicle</text>
                      <text x="95" y="110" fill="red" fontSize="40">Status</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.masterlist == 1 && (
                <Link to="/Masterlist" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#29A2C6" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#29A2C6" }} />
                      <text x="80" y="60" fill="red" fontSize="40">Master</text>
                      <text x="100" y="110" fill="red" fontSize="40">Lists</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.wanverification == 1 && (
                <Link to="/Wanverification" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#FFCB18" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#FFCB18" }} />
                      <text x="100" y="60" fill="red" fontSize="40">WAN</text>
                      <text x="40" y="110" fill="red" fontSize="40">Verification</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.financialaccounting == 1 && (
                <Link to="/Financialaccounting" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#EF597B" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#EF597B" }} />
                      <text x="70" y="60" fill="red" fontSize="40">Financial</text>
                      <text x="40" y="110" fill="red" fontSize="40">Accounting</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.messaging == 1 && (
                <Link to="/Messageing" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#73B66B" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#73B66B" }} />
                      <text x="50" y="80" fill="red" fontSize="40">Messaging</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.reports == 1 && (
                <Link to="/Report" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#FF6D31" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#FF6D31" }} />
                      <text x="80" y="80" fill="red" fontSize="40">Reports</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.adminmodule == 1 && (
                <Link to="/Adminmodule" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#29A2C6" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#29A2C6" }} />
                      <text x="90" y="60" fill="red" fontSize="40">Admin</text>
                      <text x="75" y="110" fill="red" fontSize="40">Module</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.assetmanagement == 1 && (
                <Link to="/Assetmanagement" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#FFCB18" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#FFCB18" }} />
                      <text x="100" y="60" fill="red" fontSize="40">Asset</text>
                      <text x="25" y="110" fill="red" fontSize="40">Management</text>
                    </svg>
                  </div>
                </Link>
              )}
              {tileAllowed && tileAllowed.closedcase == 1 && (
                <Link to="/Closedcase" className="btn tile_hover" style={tileStyle}>
                  <div className='text-center' style={tileTextStyle}>
                    <svg style={svgStyle}>
                      <polygon points="10,10,120,10,20,20,10,120" style={{ fill: "#EF597B" }} />
                      <polygon points="280,140,280,30,270,130,170,140" style={{ fill: "#EF597B" }} />
                      <text x="90" y="60" fill="red" fontSize="40">Closed</text>
                      <text x="100" y="110" fill="red" fontSize="40">Cases</text>
                    </svg>
                  </div>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RTRL;

// import React, { useEffect, useContext, useState } from 'react'
// import { Link, useHistory } from "react-router-dom";
// import axios from "axios";
// import "./common.css"
// import { AuthContext } from "../helpers/AuthContext";
// import Loading from './Loading'


// function RTRL() {
//   let history = useHistory();
//   const { authState } = useContext(AuthContext);
//   const [tileAllowed, setTileAllowed] = useState()
//   const [loading, setLoading] = useState(true)


//   useEffect(() => {
//     axios.get("https://rt-rl-api.aajkaa.in/auth/auth", {
//       headers: { accessToken: localStorage.getItem("accessToken") },
//     }).then((response) => {
//       if (response.data.error) {
//         history.push('/login')
//         return
//       }
//       console.log("Response data:", response.data); // Log the response data
//       axios.post("https://rt-rl-api.aajkaa.in/Usertypes/getallowedtilename", authState, {
//         headers: { accessToken: localStorage.getItem("accessToken") },
//       }).then((response) => {
//         setTileAllowed(response.data[0])
//         console.log("Tile allowed:", response.data[0]); // Log the tile allowed data
//         setLoading(false)
//       });
//     });
//   }, [authState])
//   return (
//     <div>
//       <div className="mx-5">
//         <h2 className='text-primary text-center my-4'>Welcome to Ravindra Logistics</h2>
//         <div className="row mx-2 mt-3">
//           {loading ? (
//             <Loading />
//           ) : (
//             <>
//               {tileAllowed && tileAllowed.newenquiry == 1 && <Link to="/Newenquiry" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>New Enquiry</div></Link>}
//               {tileAllowed && tileAllowed.existingenquiry == 1 && <Link to="/Existingenquiry" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Existing Enquiry</div></Link>}
//               {tileAllowed && tileAllowed.vehicalstatus == 1 && <Link to="/Vehicalstatus" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Vehicle Status</div></Link>}
//               {tileAllowed && tileAllowed.masterlist == 1 && <Link to="/Masterlist" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Master Lists</div></Link>}
//               {tileAllowed && tileAllowed.wanverification == 1 && <Link to="/Wanverification" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>WAN Verification</div></Link>}
//               {tileAllowed && tileAllowed.financialaccounting == 1 && <Link to="/Financialaccounting" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Financial Accounting</div></Link>}
//               {tileAllowed && tileAllowed.messaging == 1 && <Link to="/Messageing" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Messaging</div></Link>}
//               {tileAllowed && tileAllowed.reports == 1 && <Link to="/Report" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Reports</div></Link>}
//               {tileAllowed && tileAllowed.adminmodule == 1 && <Link to="/Adminmodule" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Admin Module</div></Link>}
//               {tileAllowed && tileAllowed.assetmanagement == 1 && <Link to="/Assetmanagement" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Asset Management</div></Link>}
//               {tileAllowed && tileAllowed.closedcase == 1 && <Link to="/Closedcase" className="btn col-md-3 border tile_hover"><div className='text-center' style={{fontSize:"1.8vw"}}>Closed Cases</div></Link>}
//             </>
//           )}
//         </div>
//       </div>
//     </div >
//   );
// }

// export default RTRL
